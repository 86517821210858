import React from "react"
import Layout from "../components/Layout"
import View from "../components/View"

const Maintenance = () => (
  <Layout>
    <View title={'Error encountered'}>
        <div className={'min-w-screen min-h-screen bg-purple-100 flex items-center p-5 lg:p-20 overflow-hidden relative'}>
            <div
                className={'flex-1 mx-auto rounded-3xl bg-white shadow-xl p-10 lg:p-20 text-gray-800 relative md:flex items-center text-center md:text-left'}
                style={{maxWidth: '1000px'}}>
                <div className={'w-full'}>
                    <div className={'mb-10'}>
                        <img src={'/logo_black.png'} alt={'4Elements logo'} className={'w-20 ml-auto'}/>
                    </div>
                    <div className={'mb-10 md:mb-20 text-gray-600 font-light'}>
                        <h1 className={'font-black uppercase text-3xl lg:text-5xl text-indigo-700 mb-10'}>
                            We'll be back soon
                        </h1>
                        <p className={'text-lg'}>We are performing scheduled maintenance. We'll be back as soon as possible.</p>
                    </div>
                </div>
            </div>
            <div
                className={'w-64 md:w-96 h-96 md:h-full bg-purple-200 bg-opacity-30 absolute -top-64 md:-top-96 right-20 md:right-32 rounded-full pointer-events-none -rotate-45 transform'}/>
            <div
                className={'w-96 h-full bg-purple-200 bg-opacity-20 absolute -bottom-96 right-64 rounded-full pointer-events-none -rotate-45 transform'}/>
        </div>
    </View>
  </Layout>
)

export default Maintenance
